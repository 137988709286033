var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex-center"
  }, [_c('img', {
    staticClass: "mr-50",
    attrs: {
      "src": require("@icons/plane-ticket.svg")
    }
  }), _c('v-select', {
    staticClass: "w-100",
    attrs: {
      "id": "airlines-search",
      "value": _vm.valueProp,
      "taggable": "",
      "multiple": "",
      "disabled": "",
      "reduce": function reduce(val) {
        return val.toUpperCase();
      },
      "options": _vm.airlineOptions,
      "label": "title",
      "placeholder": _vm.$t('flight.airlineSearchPlaceholder')
    },
    scopedSlots: _vm._u([{
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', {}, [_vm._v(_vm._s(data.title.toUpperCase()))])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }