<template>
  <div class="d-flex-center">
    <img
      src="@icons/plane-ticket.svg"
      class="mr-50"
    >
    <v-select
      id="airlines-search"
      :value="valueProp"
      class="w-100"
      taggable
      multiple
      disabled
      :reduce="val => val.toUpperCase()"
      :options="airlineOptions"
      label="title"
      :placeholder="$t('flight.airlineSearchPlaceholder')"
    >
      <template #selected-option="data">
        <span class="">{{ data.title.toUpperCase() }}</span>
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { ref, toRefs } from '@vue/composition-api'

export default {
  components: {
    vSelect,
  },
  props: {
    airlines: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const airlineOptions = ref(['VN', 'VJ', 'QH', 'VU'])
    const valueProp = toRefs(props).airlines

    return {
      valueProp,
      airlineOptions,
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables/_variables.scss';
::v-deep .vs__search {
  height: 50px;
}

#airlines-search ::v-deep {
  .vs__actions {
  display: none;
}
  .vs__search {
  height: auto;
}
.vs__deselect{
  border-radius: 4px;
  // background-color: rgba(234, 84, 85, 0.9);
  color: #EF5350;
}
.vs__selected{
// background-color: rgb(230,244,252);
background-color: transparent;
color: $dark;
border: 1px solid #ccc;
border-radius: 8px;
padding: 4px 8px;
}
.vs__selected-options {
  display: flex;
  // flex-wrap: nowrap;
}

.vs__dropdown-toggle{
  border: none;
}
}

</style>
